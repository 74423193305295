import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"

function Package() {
	return (
        <div className="package">
        <div className="deals">
        <div className="row">

      

            <div className="col-sm-6 promo">
                <h3>
                    Life is Meant for Good Friends & Great Adventures!
                </h3>
                <p>Enjoy Our All Inclusive Deals</p>
                <a href=""><button>Learn More</button></a>
            </div>
            <div className="col-sm-6 promo-image">
              <div className="featured">
                <p>All Inclusive Packages</p>
                <h3>Up to 40% Discount</h3>
                <a href=""><button id="primary-button" >Book Now</button></a>
              </div>
            </div>
            <div class="elfsight-app-8f50dbf5-c6b5-4e03-8c51-0fce73d33925"></div>
        </div>
    </div>
        </div>


    );
}

export default Package;